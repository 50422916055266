.pdfDocument {
    user-select: none;
}

.textLayer {
    user-select: text;
}

.othersSelectionLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
}

.cursorLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


body,
input,
button,
select {
  font: message-box;
  outline: none;
}

.page {
  margin: 16px;
  border: 1px solid #aaa;
  box-shadow: 1px 2px 9px 0px rgba(0,0,0,0.2);
}