.topBar {
  display: flex;
}

.left {
  flex: 1 0 auto;
}

.userList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userIcon {
  position:relative;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  border-radius: 9999px;
  border: 1px solid white;
  color: white;
  transition: margin 0.1s;
  overflow: hidden;
}

.userIcon:not(:first-child) {
  margin-left: -12px;
}

.userIcon:hover {
  margin-left: 0;
}